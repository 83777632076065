/**
 * Foursource page
 * https://foursource.com/contact-us
 */

import * as React from "react";
import { withTrans } from "../i18n/withTrans";

import { pageTexts } from "../content/contact-us";

// components
import Layout from "../components/layout";
import TextImgBackgroundFull from "../components/textImgBackgroundFull";

const MOBILE_WIDTH = 992;

class careerPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobile: (typeof window !== "undefined") ? (window.innerWidth <= MOBILE_WIDTH ? true : false):'',
    };
    this.t = props.t;
    this.text = pageTexts(this.t);
  }

  componentDidMount = () => {
    window.addEventListener("resize", this.handleResize, true);
  };

  handleResize = () => {
    let show = window.innerWidth <= MOBILE_WIDTH ? true : false;
    this.setState({
      mobile: show,
    });
  };

  render() {
    return (
      <>
        <Layout pageName="contact-us" whiteMenu={false}>
          <TextImgBackgroundFull info={this.state.mobile ? this.text.topInfoMobile : this.text.topInfo}></TextImgBackgroundFull>
          <div className="pb-50 pt-10">
            <iframe
              title={this.t("contact-us.iframeTitle")}
              id="contactForm"
              className="border-0 centerIframes"
              src={process.env.GATSBY_CHINA === "true" ? `${process.env.APP_CHINA_URL}/contact` : `${process.env.APP_URL}/contact`}
              scrolling="no"
              align="middle"
            ></iframe>
          </div>
        </Layout>
      </>
    );
  }
}

export default withTrans(careerPage);
