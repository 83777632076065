// images
import TopBackground from "../images/contact-us.jpeg";
import TopBackgroundMobile from "../images/headersMobile/9-contact-us-mobile.jpg";

export const pageTexts = (t) => {
  return {
    topInfo: {
      bottomDiagonal: true,
      opacity: false,
      textBottom: true,
      image: {
        src: TopBackground,
        alt: t("career.imgAlt"),
        center: true,
        smallHeight: true,
      },
    },
    topInfoMobile: {
      bottomDiagonal: true,
      opacity: false,
      textBottom: true,
      image: {
        src: TopBackgroundMobile,
        alt: t("career.imgAlt"),
        center: true,
        smallHeight: true,
      },
    },
    thankYou: {
      title: {
        text: t("contact-us.thanksTitle"),
        colorClass: "colorDark",
        extraClass: "font60 m-0 lineHeight1",
      },
      subtitle: {
        text: t("contact-us.thanksSubtitle"),
        colorClass: "colorDark",
        extraClass: "font50 lineHeight1 fontWeight400",
      },
      text: {
        text: t("contact-us.answerSoon"),
        colorClass: "colorDark",
        extraClass: "font20 letterSpacing2",
      },
      separator: false,
    },
    backHome: {
      text: t("contact-us.backHome"),
      url: "/",
      type: "whiteColor blackBorder",
      extraClass: "bigWidth font14",
    },
  };
};
